import React from "react"
import { startCase } from "lodash"

import { useSchedule } from "../hooks/schedule"

import "./nowPlaying.scss"

export const NowPlaying: React.FC = () => {
  let { current } = useSchedule()
  return (
    <div className="nowPlaying">
      <h3 className="statusBarHeading">Now playing</h3>
      <h2 className="statusBarSessionName">
        {current?.session?.name ?? current?.session?.createdBy?.username}
      </h2>
      <p className="statusBarSessionDetails">
        {startCase(current?.type)} {current?.type === "autonomous" && "by"}{" "}
        {current?.session?.createdBy?.twitterHandle ? (
          <a
            href={`https://twitter.com/${current?.session?.createdBy.twitterHandle}`}
          >
            {current?.session?.createdBy.username}
          </a>
        ) : (
          current?.session?.createdBy?.username
        )}{" "}
        {current?.session?.createdBy?.country && (
          <>({current?.session?.createdBy.country})</>
        )}
      </p>
    </div>
  )
}
