import { ASSETS_BASE_URL } from "../constants";

type ScheduleIndex = { from: number, to: number, fileName: string }[];
type ScheduleIndexes = { all: ScheduleIndex, performance: ScheduleIndex, special: ScheduleIndex };

export type ScheduleItem = {
    from: number,
    to: number,
    type: 'performance' | 'autonomous' | 'special',
    specialType?: string;
    session?: {
        name: string,
        description?: string,
        link?: string;
        youtubeUrl?: string;
        createdBy?: {
            username: string,
            twitterHandle: string,
            country: string
        }
    }
}
export type Schedule = {
    all: ScheduleItem[],
    performance: ScheduleItem[],
    special: ScheduleItem[]
}

export async function getSchedule(latency: number, n = 10): Promise<Schedule> {
    let now = Date.now();
    let index = await fetch(`${ASSETS_BASE_URL}/schedule/index.json`)
        .then(res => res.json()) as ScheduleIndexes;

    let results: Schedule = { all: [], performance: [], special: [] };

    loop: for (let idxItem of index.all) {
        if (idxItem.to < now - latency) continue;
        let items = await fetch(`${ASSETS_BASE_URL}/schedule/${idxItem.fileName}`).then(res => res.json()) as ScheduleItem[];
        for (let item of items) {
            if (item.to < now - latency) continue;
            results.all.push(item);
            if (results.all.length >= n) {
                break loop;
            }
        }
    }

    loop: for (let idxItem of index.performance) {
        if (idxItem.to < now - latency) continue;
        let items = await fetch(`${ASSETS_BASE_URL}/schedule/${idxItem.fileName}`).then(res => res.json()) as ScheduleItem[];
        for (let item of items) {
            if (item.to < now - latency) continue;
            results.performance.push(item);
            if (results.performance.length >= n) {
                break loop;
            }
        }
    }

    loop: for (let idxItem of index.special) {
        if (idxItem.to < now - latency) continue;
        let items = await fetch(`${ASSETS_BASE_URL}/schedule/${idxItem.fileName}`).then(res => res.json()) as ScheduleItem[];
        for (let item of items) {
            if (item.to < now - latency) continue;
            results.special.push(item);
            if (results.special.length >= n) {
                break loop;
            }
        }
    }

    return results;
}

export const POST_CTM_SCHEDULE: ScheduleItem[] = [
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'talk',
        session: {
            name: '»Repressed Possibilities – An Introduction to Apotome« by Khyam Allami',
            description: 'In this talk, artist and researcher Khyam Allami gives a short introduction to »Apotome« – a transcultural browser-based generative music system focused on using microtonal tuning systems and their subsets (scales/modes)',
            youtubeUrl: 'https://www.youtube.com/embed/GzcWzblOiSs'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'artist takeover',
        session: {
            name: 'Wahono',
            youtubeUrl: 'https://www.youtube.com/embed/1cWBLs7qZDo?autoplay=1'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'artist takeover',
        session: {
            name: 'Deena Abdelwahed',
            youtubeUrl: 'https://www.youtube.com/embed/PHHDprf2q2o?autoplay=1'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'artist takeover',
        session: {
            name: 'Slikback',
            youtubeUrl: 'https://www.youtube.com/embed/vy966kFAQmE?autoplay=1'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'panel',
        session: {
            name: '»Dismantling Western Bias in Music Software and Music Education«',
            youtubeUrl: 'https://www.youtube.com/embed/lwvVj2mPY0Q?autoplay=1'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'live',
        session: {
            name: 'Faten Kanaan with Nene H, Tot Onyx, Enyang Ha, Tyler Friedman, Lucy Railton',
            youtubeUrl: 'https://www.youtube.com/embed/HLBKKs_iwB0?autoplay=1'
        }
    },
    {
        from: 0,
        to: 0,
        type: 'special',
        specialType: 'live',
        session: {
            name: 'Khyam Allami with Nene H, Tot Onyx, Enyang Ha, Tyler Friedman, Lucy Railton',
            youtubeUrl: 'https://www.youtube.com/embed/Zn8XHijHUm8?autoplay=1'
        }
    }
]