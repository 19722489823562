import React, { useState } from "react"

import Layout from "../components/layout"
import { NowPlaying } from "../components/nowPlaying"
import { Schedule } from "../components/schedule"
import SEO from "../components/seo"
import Video from "../components/video"
import { ScheduleContext } from "../hooks/schedule"

import Logo from "../images/logoApotome.png"
import { POST_CTM_SCHEDULE } from "../services/schedule"

import "./index.scss"

const IndexPage: React.FC = () => {
  let [scheduleCtxValue, setScheduleCtxValue] = useState({
    schedule: POST_CTM_SCHEDULE,
    currentIndex: 0,
    setCurrentIndex: (i: number) => setScheduleCtxValue(v => ({...v, currentIndex: i}))
  })
  return (
    <ScheduleContext.Provider value={scheduleCtxValue}>
      <Layout pageClass="index">
        <SEO title="Apotome" />
        <div className="videoWrapper">
          <Video title="Apotome CTM Live" />
        </div>
        <div className="statusBar">
          <h1>
            <img className="apotomeLogo" src={Logo} alt="Apotome" />
          </h1>
          <div className="statusBarContent">
            <NowPlaying />
          </div>
        </div>
        <div className="info">
          <div className="infoContent">
            <Schedule />
            <div className="description">
              <h3 className="descriptionIntro">
                Apotome is a transcultural browser-based generative music
                system, focused on using microtonal tuning systems and their
                subsets (scales/modes). It was created by{" "}
                <a href="https://khyamallami.com/">Khyam Allami</a> and
                <a href="https://ctpt.co/"> Counterpoint</a> and launched at CTM
                2021 »Transformation«.
              </h3>
              <p>
                Alongside its sister application Leimma, these non-commercial
                environments are an effort to highlight the cultural asymmetries
                and biases inherent in modern music-making tools, alongside
                their interconnected web of musical, educational, cultural,
                social, and political ramifications. They are an attempt to
                present decolonised music applications that allow music-makers
                from any culture to have the freedom of a musical tabula rasa
                (blank slate) and explore their individual creative ideas with
                modern tools, but without a specific end-result as a goal.
              </p>
              <p>
                Anyone can access{" "}
                <a href="https://isartum.net/apotome">Apotome</a> (and its
                sister application{" "}
                <a href="https://isartum.net/leimma">Leimma</a>) to explore the
                tuning systems of various cultures, or create their own. Users
                can submit compositions, and during CTM Festival they could even
                
                  sign up to »perform« Apotome live
               
                by manipulating its parameters, whilst the result was broadcast
                both within{" "}
                <a href="https://www.ctm-festival.de/festival-2021/programme/exhibition/cyberia">
                  CTM Cyberia
                </a>{" "}
                and on this page, as part of a 24/7 stream running throughout
                the festival.
              </p>
              <p>
                For CTM 2021, Allami and Counterpoint{" "}
                <a href="https://www.ctm-festival.de/festival-2021/programme/features/apotome">
                  presented Apotome in myriad forms
                </a>
                : a presentation, artist takeovers by Deena Abdelwahed, Wahono
                and Slikback, a panel discussion and a live trans/local
                networked performance by Khyam Allami and Faten Kanaan with Nene
                H, Tot Onyx, Enyang Ha, Tyler Friedman and Lucy Railton.
              </p>
              <div className="credits">
                <p>
                  Khyam Allami: Concept design and direction, research,
                  composition
                </p>
                <p>
                  Tero Parviainen: Software development, UI engineering, concept
                  design, generative music system design
                </p>{" "}
                <p>
                  Samuel Diggins: Creative direction, concept design, UI
                  engineering, generative visual design.
                </p>{" "}
                <p>
                  OBXD, DEXED, and Yoshimi{" "}
                  <a href="https://www.webaudiomodules.org/">
                    Web Audio Modules
                  </a>{" "}
                  by Jari Kleimola{" "}
                </p>{" "}
                <p>
                  The development of Apotome was funded via the AHRC
                  Midlands4Cities Doctoral Training Partnership with additional
                  support from CTM Festival.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </ScheduleContext.Provider>
  )
}

export default IndexPage
