import { createContext, useContext } from "react";
import * as scheduleService from '../services/schedule';

type ScheduleContextProps = {
    schedule: scheduleService.ScheduleItem[],
    currentIndex: number,
    setCurrentIndex: (idx: number) => void,
}

export let ScheduleContext = createContext<ScheduleContextProps>({
    schedule: [],
    currentIndex: 0,
    setCurrentIndex: () => { }
})


export function useSchedule() {
    let ctx = useContext(ScheduleContext);

    return { special: ctx.schedule, current: ctx.schedule[ctx.currentIndex], setCurrentIndex: ctx.setCurrentIndex };
}