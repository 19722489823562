import React from "react"
import dateFormat from "dateformat"
import classNames from "classnames"

import { useSchedule } from "../hooks/schedule"

import "./schedule.scss"

export const Schedule: React.FC = () => {
  let { current,  special, setCurrentIndex } = useSchedule()
  let listToShow = special

  return (
    <div className="schedule">
      {listToShow.map((item, idx) => (
        <React.Fragment key={item.from}>
          <div
            className={classNames("schedule--item", {
              isCurrent: item === current,
            })}
          >
            <span className="schedule--type">
              {item.specialType}
            </span>
            <span className="schedule--session">
                <a href='#' onClick={(evt) => {setCurrentIndex(idx); evt.preventDefault();}}>{item.session?.name}</a>
              {item.session?.createdBy?.username && <>by </>}
              {item.session?.createdBy?.twitterHandle ? (
                <a
                  href={`https://twitter.com/${item.session?.createdBy.twitterHandle}`}
                >
                  {item.session?.createdBy.username}
                </a>
              ) : (
                item.session?.createdBy?.username
              )}{" "}
              {item.session?.createdBy?.country && (
                <>({item.session?.createdBy.country})</>
              )}
            </span>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
