import React from "react"
import { useSchedule } from "../hooks/schedule"

import "./video.scss"

export const VIDEO_LATENCY = 30 // Estimate of youtube latency

interface VideoProps {
  title: string
}
const Video: React.FC<VideoProps> = ({ title }) => {
  let { current } = useSchedule()
  let srcUrl =
    current?.session?.youtubeUrl ?? "https://isartum.net/embed/ctm/web?bust=1"

  return (
    <div className="video">
      <iframe
        src={`${srcUrl}`}
        title={title}
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      />
    </div>
  )
}
export default Video
